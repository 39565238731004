@charset "utf-8";


$body-family: "Optima, Segoe, " Segoe UI ", Candara, Calibri, Arial, sans-serif;";

//@import url('https://fonts.googleapis.com/css?family=Nunito:400,700')
//$family-sans-serif: 'Nunito', sans-serif

$footer-background-color: #ffffff;

@import "~bulma/bulma";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/layout/all";
@import "~bulma/sass/elements/all";
@import "~bulma/sass/base/all";
@import "~bulma/sass/utilities/all";
@import "~bulma/sass/components/navbar";

.black-border {
  border: 5px solid black;
  padding-top: 2px;
  padding-bottom: 2px; }
